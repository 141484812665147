<template>
  <div id="m_index">
    <div class="top d_flex">
      <div class="logo">
        <img :src="$store.state.tenantInfo.logo | fullPath" alt="" />
      </div>
      <div class="right_content d_flex">
        <template v-if="!isLogin">
          <span class="login" @click="$router.push('/m_login')">登录</span>
          <span class="registered" @click="$router.push('/m_register')"
            >注册</span
          >
        </template>
        <div class="user" v-else>
          <img
            src="@/assets/img/mobile/10.png"
            alt=""
            @click="$router.push('/member/index')"
          />
        </div>
        <span @click="show = !show"
          ><img src="@/assets/img/mobile/nav_bar.png" alt=""
        /></span>
      </div>
    </div>
    <div class="bg_img">
      <el-carousel height="146px" style="padding: 12px 14px 0">
        <el-carousel-item v-for="item in advertList">
          <div class="user_adv" v-if="item.image" @click="toUrl(item.url)">
            <img
              :src="item.image | fullPath"
              alt=""
              style="height: 146px; border-radius: 10px"
            />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="activity">
      <div><img src="@/assets/img/mobile/01.png" alt="" /></div>
      <div><img src="@/assets/img/mobile/02.png" alt="" /></div>
      <div><img src="@/assets/img/mobile/03.png" alt="" /></div>
      <div><img src="@/assets/img/mobile/04.png" alt="" /></div>
    </div>
    <div class="activity_title">热销推荐</div>
    <div class="good_list">
      <div class="item"><img src="@/assets/img/05-1.png" alt="" /></div>
      <div class="item" v-for="item in hotgoodsList" @click="buyGoods(item)">
        <div class="img_wrap"><img :src="item.cover | fullPath" alt="" /></div>
        <div class="title">{{ item.name }}</div>
        <div class="other clearfix">
          <span class="price fl"
            >￥{{ (item.cost + item.profit).toFixed(2) }}</span
          >
          <span class="wei fr">{{ item.weight }} kg/件</span>
        </div>
        <div class="btn">立即购买</div>
      </div>
    </div>
    <div class="activity_title">新品上架</div>
    <div class="good_list">
      <div class="item"><img src="@/assets/img/05-1.png" alt="" /></div>
      <div class="item" v-for="item in newestGoodsList" @click="buyGoods(item)">
        <div class="img_wrap"><img :src="item.cover | fullPath" alt="" /></div>
        <div class="title">{{ item.name }}</div>
        <div class="other clearfix">
          <span class="price fl"
            >￥{{ (item.cost + item.profit).toFixed(2) }}</span
          >
          <span class="wei fr">{{ item.weight }} kg/件</span>
        </div>
        <div class="btn">立即购买</div>
      </div>
    </div>
    <div class="section4">
      <span>新闻动态</span>
      <div class="article_list">
        <div
          class="article_item"
          v-for="item in articleList2"
          @click="$router.push('/news/' + item.id + '?type=2')"
        >
          <div class="title">
            <a>{{ item.title }}</a>
            <p>{{ $time.getDate(item.createTime) }}</p>
          </div>
          <div class="summary">{{ item.summary }}</div>
        </div>
      </div>
    </div>
    <div class="bottom_content d_flex">
      <div class="b_item">
        <div class="d_flex">
          <img src="@/assets/img/bottom1.png" alt="" />
          <p>
            <span>降低成本</span><br />
            <span>帮你降低50%发货成本</span>
          </p>
        </div>
        <div class="d_flex">
          <img src="@/assets/img/bottom2.png" alt="" />
          <p>
            <span>保证品质</span><br />
            <span>注重品质，物廉价美</span>
          </p>
        </div>
      </div>
      <div class="b_item">
        <div class="d_flex">
          <img src="@/assets/img/bottom4.png" alt="" />
          <p>
            <span>去除库存</span><br />
            <span>回收库存、去礼品库</span>
          </p>
        </div>
        <div class="d_flex">
          <img src="@/assets/img/bottom3.png" alt="" />
          <p>
            <span>多种选择</span><br />
            <span>低价礼品、配重礼品、高端礼品</span>
          </p>
        </div>
      </div>
    </div>
    <div class="copyright" v-if="$store.state.tenantInfo.beianhao">
      <img src="@/assets/img/guohui.png" alt="" />
      {{ $store.state.tenantInfo.beianhao }}
    </div>
    <transition name="fade">
      <div class="mask" v-if="show" @click="show = false"></div>
    </transition>
    <transition name="fade1">
      <div class="mask_left" v-if="show">

        <div @click="$router.push('/m_goodsall')">礼品商城</div>
        <div @click="$router.push('/member/index')">会员中心</div>

        <div @click="$router.push('/member/guide')">代发指南</div>

        <div @click="openQQ">在线客服</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      isLogin: false,
      show: false,
      hotgoodsList: [],
      newestGoodsList: [],
      articleList2: [],
      advertList: [],
    };
  },
  created() {
    let token = localStorage.getItem("dftToken");
    if (token) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    this.getAdvertList();
    this.getArticle2();
    this.getHotGoodsList();
    this.IsPC();
  },
  mounted() {},
  watch: {},
  methods: {

    getArticle2() {
      this.$request.post({
        url: "/ReportApi/business/article/getArticleInfo",
        params: {
          pageNo: 1,
          pageSize: 6,
          type: 2,
        },
        success: (res) => {
          this.articleList2 = res.data;
        },
      });
    },
    openQQ() {
      var url =
        "tencent://message/?uin=" +
        this.$store.state.tenantInfo.qq +
        "&amp;Site=&amp;Menu=yes";
      window.open(url, "_self");
    },

    getHotGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goods/getGoodsInfo",
        params: {
          pageNo: 1,
          pageSize: 11,
          sort: 3,
        },
        success: (res) => {
          this.hotgoodsList = res.data;
          if (this.hotgoodsList.length < 11) {
            this.hotNull = 11 - this.hotgoodsList.length;
          }
          this.getNewestGoodsList();
        },
      });
    },

    getNewestGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goods/getGoodsInfo",
        params: {
          pageNo: 1,
          pageSize: 11,
          sort: 5,
        },
        success: (res) => {
          this.newestGoodsList = res.data;
          this.newNull = 11 - this.newestGoodsList.length;
        },
      });
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (flag) {
        this.$router.push("/index");
      }
    },
    getAdvertList() {
      this.$request.post({
        url: "/ReportApi/business/advert/getAdvertInfo",
        params: {
          pageNo: 1,
          pageSize: 1,
          type: 1,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {



          this.advertList = res;
        },
      });
    },
    toUrl(url) {
      if (url.indexOf("http") != -1) {
        window.location.href = url;
      } else {
        this.$router.push(url);
      }
    },
    buyGoods(item) {
      this.$store.commit("getGoodsInfo", item);
      this.$router.push("/member/send_order");
    },
  },
};
</script>

<style lang="scss" scoped>
#m_index {
  .user_adv {
    cursor: pointer;
    margin-bottom: 10px;
    img {
      width: 1200px;
    }
    height: 100%;
  }
  padding-top: 44px;
  .top {
    position: fixed;
    top: 0;
    background: #ffffff;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px;
    height: 44px;
    z-index: 10;
    .logo {
      align-items: center;
      width: 57.6px;
      img {
        width: 100%;
        display: block;
      }
    }
    .right_content {
      align-items: center;
      span {
        &:last-child {
          display: inline-block;
          width: 18px;
          height: 18px;
          img {
            width: 100%;
          }
        }
      }
      .login {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        background: #ff8800;
        border-radius: 12px;
        padding: 2px 10px;
      }
      .registered {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff8800;
        padding: 2px 10px;
        border: 1px solid #ff8800;
        border-radius: 12px;
        margin: 0 14px 0 5px;
      }
      .user {
        width: 18px;
        height: 18px;
        display: block;
        margin-right: 18px;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
  .bg_img {
    img {

      width: 100%;
      display: block;
    }
  }
  .activity {
    padding: 12px 14px 0;
    font-size: 0;
    div {
      display: inline-block;
      width: 49%;
      &:nth-child(even) {
        margin-left: 2%;
      }
      &:nth-child(n + 3) {
        margin-top: 8px;
      }
      img {
        width: 100%;
      }
    }
  }
  .activity_title {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    margin-top: 12px;
    margin-left: 14px;
    padding-left: 5px;
    border-left: 4px solid #ff8800;
  }
  .good_list {
    font-size: 0;
    padding: 4px 14px 0;
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 49%;
      display: inline-block;
      border: 1px solid #eee;
      box-sizing: border-box;
      vertical-align: top;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &:nth-child(even) {
        margin-left: 2%;
      }
      &:nth-child(n + 3) {
        margin-top: 8px;
      }
      .img_wrap {
        height: calc(49vw - 14px);
      }
      .title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 10px;
        color: #999;
        line-height: 22px;
        padding: 0 5px;
      }
      .other {
        padding: 0 5px;
        .price {
          font-size: 14px;
          color: #ff0036;
        }
        .wei {
          font-size: 11px;
          color: #666;
        }
      }
      .btn {
        line-height: 28px;
        text-align: center;
        color: #ea242f;
        border: 1px solid #ea242f;
        box-sizing: border-box;
        border-radius: 5px;
        margin: 6px 9px 2% 9px;
        display: block;
        height: 28px;
        font-size: 12px;
        &:hover {
          color: #fff;
          background: #ea242f;
        }
      }
    }
  }
  .section4 {
    position: relative;
    text-align: center;
    margin-bottom: 25px;
    background: #fff;
    & > span {
      position: relative;
      display: inline-block;
      margin: 25px auto;
      font-weight: 700;
      font-size: 22px;
      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        display: inline-block;
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          from(rgba(255, 166, 0, 0.929412)),
          to(#ea242f)
        );
        background: linear-gradient(
          90deg,
          rgba(255, 166, 0, 0.929412),
          #ea242f
        );
        width: 40%;
        border-radius: 50px;
        height: 4px;
      }
    }
    .article_list {
      text-align: left;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      flex-wrap: wrap;
      .article_item {
        border: 1px solid #eee;
        padding: 10px 12px;
        width: 96%;
        box-sizing: border-box;
        margin: 10px auto;
        cursor: pointer;
        &:hover {
          box-shadow: 0 0 5px 1px #dcdfe6;
          .title a {
            color: #ea242f;
          }
        }
        .title {
          display: -webkit-box;
          display: flex;
          align-items: center;
          -webkit-box-pack: justify;
          justify-content: space-between;
          margin-bottom: 10px;
        }
        .summary {
          color: #767676;
          font-size: 14px;
        }
        .title a {
          font-size: 18px;
          color: #333;
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right: 10px;
        }
        .title {
          p {
            color: #999;
          }
        }
      }
    }
  }
  .bottom_content {
    padding: 0 14px;
    margin-top: 10px;
    .b_item {
      flex: 1;
      div {
        img {
          width: 74px;
        }
        p {
          margin-top: 15px;
          span {
            &:first-child {
              color: #000000;
              font-size: 14px;
              font-weight: bold;
            }
            &:last-child {
              font-size: 10px;
              color: #999;
            }
          }
        }
      }
    }
  }
  .friend_link {
    margin-top: 15px;
    text-align: center;
    span {
      font-size: 10px;
      color: #333333;
      &:first-child {
        color: #666666;
      }
    }
    a {
      margin-right: 5px;
      font-size: 10px;
    }
  }
  .copyright {
    text-align: center;
    font-size: 8px;
    color: #333333;
    margin-top: 9px;
    padding-bottom: 42px;
    img {
      height: 16px;
    }
  }
  .mask {
    position: fixed;
    top: 44px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 11;
  }
  .mask_left {
    width: 35%;
    text-align: center;
    position: fixed;
    top: 44px;
    background: #fff;
    bottom: 0;
    right: 0;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    z-index: 11;
    div {
      padding: 9px 0;
      border-bottom: 1px solid #eee;
    }
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade1-enter,
  .fade1-leave-to {
    transform: translateX(100%);
  }
  .fade1-enter-active,
  .fade1-leave-active {
    transition: all 0.5s;
  }
}
</style>
